import React, { useState, useEffect } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "../../../../components/Wrappers/Wrappers";
// import pdfIcon from "../../../../images/pdf-icon.svg";
import downloadIcon from "../../../../images/download-icon.svg";
import crossIcon from "../../../../images/cross-icon.svg";
import deleteIcon from "../../../../images/delete-icon.svg";
import { alertActions } from "../../../../_actions";
import { Checkbox } from "@material-ui/core";
import { fileService } from "../../../../_services/file.service";
import { CONST } from "./../../../../_config/index";
import axios from "axios";
import { authHeader } from "./../../../../_helpers/auth-header";

let fileInput = "";

export default function LandlordDocumentsDialog(props) {
  let {
    show,
    handleClose,
    siteInfo,
    location,
    landlordId,
    locationHandler,
  } = props;
  const { type, data } = siteInfo;
  // const fullWidth = "60vw";

  const [fileSelected, setFileSelected] = useState("");
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({ names: [], links: [] });
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    if (data) {
      const { fileNames, fileLinks } = data;
      setUploadedFiles((prevState) => {
        return {
          ...prevState,
          names: fileNames,
          links: fileLinks,
        };
      });
    }
  }, [data]);

  const onDeleteFiles = () => {
    onDeleteFile(checkboxSelected);
  };

  const onDeleteFile = (fileName) => {
    if (!fileName) {
      return;
    }

    if (window.confirm("Are you sure want to delete?")) {
    } else {
      return;
    }


    const requestData = {
      locationId: location.id,
      removeFileName: fileName,
      siteInfoType: type,
    };

    fileService
      .deleteFiles(requestData, setProgress)
      .then((res) => {
        if (res.data.message) {
          const fileNames = res.data.message[type];
          const fileLinks = res.data.message[`${type}FileLinks`];
          setUploadedFiles((prevState) => {
            return {
              ...prevState,
              names: fileNames,
              links: fileLinks,
            };
          });
          setCheckboxSelected([]);
          setFileSelected("");
          locationHandler(location, false);
        }
        alertActions.success("File deleted.");
      })
      .catch((err) => {
        console.log("errrrrrr", err);
        alertActions.error(err);
      });
  };

  const fileSelectedHandler = (event) => {
    let fileArray = Array.from(event.target.files);
    if (fileSelected && fileSelected.length > 0) {
      setFileSelected([...fileSelected, ...fileArray]);
    } else {
      setFileSelected(fileArray);
    }
  };

  const handleChange = (event, fileName) => {
    let fileArr = [...checkboxSelected];
    if (event.target.checked) {
      fileArr.push(fileName);
    } else {
      fileArr = fileArr.filter((item) => item !== fileName);
    }
    setCheckboxSelected(fileArr);
  };

  const handleOnSubmit = () => {
    if (!fileSelected && fileSelected.length < 1) {
      return;
    }
    const formData = new FormData();
    formData.append("landlordId", landlordId);
    formData.append("locationId", location.id);
    formData.append("siteInfoType", siteInfo.type);
    fileSelected.map((file, i) => {
      formData.append("file", fileSelected[i]);
    });
    const header = {
      Authorization: authHeader().Authorization,
    };
    // fileService
    //   .uploadFiles(formData)
    axios
      .post(CONST.BACKEND_URL + "/upload/siteInfo", formData, {
        headers: header,
        onUploadProgress: (progressEvent) => {
          console.log("progressEvent", progressEvent);
          console.log(
            "Upload progress",
            Math.floor((progressEvent.loaded / progressEvent.total) * 100) +
            "%",
          );
          setProgress(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100),
          );
        },
      })
      .then((res) => {
        if (res.data.message) {
          const fileNames = res.data.message[type] || [];
          const fileLinks = res.data.message[`${type}FileLinks`] || [];
          setUploadedFiles((prevState) => {
            return {
              ...prevState,
              names: fileNames,
              links: fileLinks,
            };
          });
        }
        locationHandler(location, false);
        setFileSelected("");
        setCheckboxSelected([]);
        alertActions.success("File uploaded.");
        setProgress(0);
      })
      .catch((err) => {
        console.log("errrrrrr", err);
        alertActions.error(err);
        setProgress(0);
        setFileSelected("");
        setCheckboxSelected([]);
      });
  };

  const removeSelectedFile = (index) => {
    const files = [...fileSelected];
    files.splice(index, 1);
    setFileSelected(files);
  };

  const onDownloadFile = (fileName) => {
    console.log('111111111',fileName);
    fileService
      .downloadFile(fileName)
      .then((response) => {
        console.log('response_____________', response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log('urlurl____________', url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName.substr(13));
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getTitle = (text) => {
    if(text === "liftEquipment") {
      return "Elevator Equipment"
    }
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  return (
    <Dialog open={show} onClose={handleClose} fullWidth={true}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">More Details</Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{ minHeight: "40vh", paddingBottom: "20px", paddingTop: "10px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "baseline",
            overflowX: "hidden",
          }}
        >
          <Button
            color="inherit"
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
            onClick={handleClose}
            disabled={progress ? true : false}
          >
            <img src={crossIcon} style={{ height: "16px" }} alt=" " />
          </Button>
          <h4
            style={{
              fontSize: "20px",
              fontWeight: "500",
              margin: "0px",
            }}
          >
            {getTitle(siteInfo.type)}
          </h4>
        </div>
        {checkboxSelected.length === 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginBottom: "14px",
            }}
          >
            <Button
              color="success"
              size="small"
              className="px-2"
              title="Choose files"
              style={{ marginRight: "25px" }}
              onClick={() => fileInput.click()}
              disabled={progress ? true : false}
            >
              Choose files
            </Button>
            <Button
              color="success"
              size="small"
              className="px-2"
              title="Click to upload"
              style={{
                marginRight: "25px",
              }}
              onClick={() => handleOnSubmit()}
              disabled={progress || !fileSelected ? true : false}
            >
              Upload
            </Button>
            <input
              type="file"
              ref={(fileinput) => (fileInput = fileinput)}
              style={{ display: "none" }}
              onChange={fileSelectedHandler}
              multiple
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginBottom: "14px",
            }}
          >
            <Button
              color="success"
              size="small"
              className="px-2"
              title="More Details"
              style={{ marginRight: "25px" }}
              onClick={() => onDeleteFiles()}
            >
              Delete
            </Button>
          </div>
        )}

        {/* <div> */}
        {fileSelected && (
          <div>
            <Box
              style={{
                alignItems: "center",
                flexDirection: "row",
                width: "93%",
                marginLeft: "25px",
                display: progress ? "flex" : "none",
              }}
            >
              <Box style={{ minWidth: 70 }}>
                <Typography variant="body2" color="text.secondary">
                  Uploading
                </Typography>
              </Box>
              <Box style={{ width: "100%", mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={{ height: "10px", borderRadius: "5px" }}
                />
              </Box>
              <Box style={{ minWidth: 35, marginLeft: "5px" }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${progress}%`}</Typography>
              </Box>
            </Box>

            <ol>
              {fileSelected &&
                fileSelected.map((file, index) => (
                  <li
                    key={file.name + index}
                    style={{
                      marginBottom: "5px",
                      marginRight: "25px",
                      background: "#f6f7f8",
                    }}
                  >
                    <span style={{ float: "left" }}>{file.name}</span>
                    <button
                      type="button"
                      style={{
                        cursor: progress ? "not-allowed" : "pointer",
                        float: "right",
                      }}
                      onClick={() => removeSelectedFile(index)}
                      disabled={progress ? true : false}
                    >
                      X
                    </button>
                  </li>
                ))}
            </ol>
          </div>
        )}

        {/* {(!uploadedFiles || uploadedFiles.names === 0) && (
          <div style={{ textAlign: "center" }}>
            <p>No files</p>
          </div>
        )} */}

        {(!uploadedFiles || !uploadedFiles.names || uploadedFiles.names.length === 0) && (
          <div style={{ textAlign: "center" }}>
            <p>No files</p>
          </div>
        )}
        {uploadedFiles &&
          uploadedFiles.names &&
          uploadedFiles.names.map((name, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#f6f7f8",
                paddingTop: "2px",
                marginBottom: "2px",
              }}
            >
              <div
                style={{
                  width: "75%",
                }}
              >
                <Checkbox
                  key={name}
                  style={{ marginLeft: "10px" }}
                  color="primary"
                  onChange={(e) => handleChange(e, name)}
                  disabled={fileSelected && fileSelected.length > 0}
                ></Checkbox>
                <p
                  style={{
                    fontSize: "18px",
                    margin: "0px",
                    fontWeight: "500",
                    textAlign: "left",
                    display: "inline-block",
                  }}
                >
                  {name.substr(13)}
                </p>
              </div>
              <div>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                  onClick={() => onDownloadFile(name)}
                >
                  <img src={downloadIcon} width="17px" alt=" " />
                </Button>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    marginLeft: "20px",
                    visibility:
                      checkboxSelected.length === 0 ? "visible" : "hidden",
                  }}
                  onClick={() => onDeleteFile([name])}
                >
                  <img src={deleteIcon} width="15px" alt=" " />
                </Button>
              </div>
            </div>
          ))}
        {/* </div> */}
      </div>
    </Dialog>
  );
}
