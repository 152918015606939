import React, { Component } from "react";
// import Dropdown from 'react-dropdown';
import "react-dropdown/style.css";

import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
// import logo from "./logo.svg";
import { userActions } from "../../_actions";

const styles = (theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
});
class Login extends Component {
  constructor(props) {
    document.title = "ATS | Login";
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      email: "",
      password: "",
      selectValue: "",
    };
  }
  componentDidMount() { }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  loginSubmit(e) {
    e.preventDefault();
    let { selectValue } = this.state;
    let { email, password } = this.state;
   this.props.dispatch(userActions.login({ username: email, password }));
  }



  navigate = () => {
    this.props.history.push('/forgotpassword');
  }

  handleChange(e) {
    console.log("value in handleChange funtion :", e.target.value);

    this.setState({ selectValue: e.target.value });
    // e.preventDefault();
    // let { email, password } = this.state;
    // this.props.dispatch(userActions.login({ username: email, password }));
  }

  render() {
    //let activeTabId = 0;
    // const options = [
    //   'admin', 'landlord'
    // ];
    // const defaultOption = options[0];
    // var message = "You selected " + this.state.selectValue;

    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <img
            src="./Logo V2.jpg"
            alt="logo"
            className={classes.logotypeImage}
          />
          <Typography className={classes.logotypeText}>ATS</Typography>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            {/* <div>
              <select
                value={this.state.selectValue}
                onChange={this.handleChange}
              >
                <option value="Admin">ATS</option>
                <option value="Landlord">Administrator</option>
              </select>
            </div> */}

            <React.Fragment>
              {/* <label>loginAs : </label><select name="menudrop" id="adminselect">
                <option value="admin">admin</option>
                <option value="landlord">landlord</option>
              </select>  <br /> */}
              <h2>ATS</h2>
              <TextField
                id="email"
                name="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={this.state.email}
                onChange={this.inputChange}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                name="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={this.state.password}
                onChange={this.inputChange}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {false ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      this.state.email.length === 0 ||
                      this.state.password.length === 0
                    }
                    onClick={this.loginSubmit}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}

                {this.state.selectValue === 'Landlord' ? <>
                  <span> <Typography color="primary"
                    type="body1"
                    hover
                  >
                    <Button
                      onClick={this.navigate}
                      // variant="contained"
                      color="primary"
                      size="small"
                    >
                      Forgot Password
                    </Button>
                  </Typography></span>
                </> : null}

              </div>
            </React.Fragment>
          </div>
          <Typography color="primary" className={classes.copyright}>
            © 2020-2021 ATC, LLC. All rights reserved.
          </Typography>
        </div>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    users,
  };
}
export default connect(mapStateToProps)(withStyles(styles)(Login));
//export default withRouter(Login);
