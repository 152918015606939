import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  // CssBaseline,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  // InputLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import DatePicker, { } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddJobLandlordDialog from "./components/AddJobLandlordDialog/AddJobLandlordDialog";
import UpdateJobLandlordDialog from "./components/UpdateJobLandlordDialog/UpdateJobLandlordDialog";
import ViewJobLandlordDialog from "./components/ViewJobLandlordDialog/ViewJobLandlordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { joblandlordActions, staffActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";

import axios from "axios";
import { CONST } from "../../_config";
import { authHeader } from "../../_helpers";

import styles from "./styles";
const theme = createMuiTheme();

const dmID = "6019c8929fba3c2d3b9dad25";
const pmID = "6019c001d78e4b27bd2ee187";
const format = "YYYY-MM-DD";

class JobLandlordManagement extends Component {
  constructor(props) {
    document.title = "ATS | LandLord";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleAddClose = this.handleAddClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.handleOpenViewCreateModal = this.handleOpenViewCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      siteInfoData: {},
      locationData: {},
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      startDate: "",
      endDate: "",
      ViewjoblandlordCreateModal: false,
      joblandlordCreateModal: false,
      updatejoblandlordCreateModal: false,
      ViewjoblandlordPdfModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        job_title: "",
        jobType_Id: "",
        job_id: "",
        location_id: "",
        assign_to: "",
        assinged_IS: null,
        job_details: "",
        designation_Id: "",
        jobType: "",
        assignToId: "CONTRACTOR",
        internalId: "",
        opt: [],

        // "name": "",
        // "address": "",
        // "lat": "",
        // "desc": "",
        // "long": "",
        // "mobile": "",
        // "rating": "",
        // "logo": "",
        // "openTime": "",
        // "closeTime": "",
        // "countryCode": "",
        // "type": ""
      },
      updateformData: {
        job_title: "",
        jobType_Id: "",
        job_id: "",
        location_id: "",
        assign_to: "",
        assinged_IS: null,
        job_details: "",
        designation_Id: "",
      },
      searchInput: {
        searchTitle: '',
        searchJobType: '',
        searchAddr: '',
        searchDesig: '',
      },
      selectedColoumn: "",
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
      filter: "pending",
      // columnName: "",
    };

    let dataReq = {
      keyWord: "",
      pageNo: 1,
      roll_id: 3,
      size: this.state.size,
      // sortBy: this.state.sortBy,
      // sortOrder: this.state.sortOrder
    };

    let dataTemp = {
      "keyWord": "",
      "pageNo": 1,
      roll_id: 1,
      "size": 10
    };
    this.props.dispatch(staffActions.getInternalStaffByJob(dataReq));
    this.props.dispatch(joblandlordActions.getDesignationList());
    // this.props.dispatch(joblandlordActions.getJobList());
    this.props.dispatch(joblandlordActions.getLocationList());
    this.props.dispatch(joblandlordActions.getJobTypeList());
    this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
    this.props.dispatch(joblandlordActions.getContractTypeList(dataTemp));

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.joblandlords.addJobLandlordSuccess) {
      return {
        ...nextProps,
        formData: {
          job_title: "",
          jobType_Id: "",
          job_id: "",
          location_id: "",
          assign_to: "",
          assinged_IS: null,
          job_details: "",
          designation_Id: "",
        },
        joblandlordCreateModal: false,
        updatejoblandlordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  handleClick = (offset, page) => {
    this.setState({ offset, page });
    if (this.state.startDate && this.state.endDate) {
      let data = {
        // columnName: this.state.selectedColoumn,
        filter: "pending",
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        // keyWord: this.state.keyWord,
        pageNo: page,
        size: this.state.size,
      };
      this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
    }
    else {
      let data = {
        columnName: this.state.selectedColoumn,
        filter: "pending",
        keyWord: this.state.keyWord,
        pageNo: page,
        size: this.state.size,
      };
      this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
    }
  };
  handleClickSearch = (offset, page) => {
    this.setState({ offset, page });
    if (this.state.searchInput) {
      if (this.state.startDate && this.state.endDate) {
        let data = {
          // columnName: this.state.selectedColoumn,
          filter: "pending",
          searchInput: this.state.searchInput,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          pageNo: page,
          size: this.state.size,
        };
        this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
      }
      else {
        let data = {
          filter: "pending",
          searchInput: this.state.searchInput,
          keyWord: this.state.keyWord,
          pageNo: page,
          size: this.state.size,
        };
        this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
      }

    }

    else {
      let data = {
        // columnName: this.state.selectedColoumn,
        filter: "pending",
        keyWord: this.state.keyWord,
        pageNo: page,
        size: this.state.size,
      };
      this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
    }
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      columnName: this.state.selectedColoumn,
      filter: "pending",
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
  }
  handleOnSelectChange(event) {
    const { value } = event.target;
    let data = {
      columnName: this.state.selectedColoumn,
      filter: "pending",
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
    };
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          jobType: value,
        },
        keyWord: value,
        offset: 0,
      };
    });
    this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
  }

  handleOpenCreateModal() {
    this.setState({ joblandlordCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updatejoblandlordCreateModal: true, updateformData: data });
  }

  deleteJob = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "jobId": data.id,
    }
    console.log('datatempdatatemp_________', datatemp);
    // this.props.dispatch(joblandlordActions.deleteJob(datatemp))

    confirmAlert({

      title: 'Confirm to Delete Job?',
      message: `Are you sure to delete ${data.job_title} job?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(joblandlordActions.deleteJob(datatemp))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenViewCreateModal(data) {

    console.log("!!!!!!!!!!!!!!!!!!!!!!2254:", data.location_id);

    this.setState({ ViewjoblandlordCreateModal: true, viewRowData: data });

    this.locationHandler(data.location_id)
  }


  locationHandler = (location, reload) => {
    if (reload) {
      // setLoading(true);
    }
    // setLocation(location);
    axios
      .post(
        CONST.BACKEND_URL2 + "/getSiteInfo",
        { locationId: location.id },
        {
          headers: {
            Authorization: authHeader().Authorization,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        if (res.data.message) {
          this.setState({ siteInfoData: res.data.message })
          // setSiteInfoData(res.data.message);
        }
      })
      .catch((err) => {
        console.log("errrrrrr", err);
        // setLoading(false);
      });
  };

  handleClose() {
    this.setState({ ViewjoblandlordCreateModal: false });
  }
  handleAddClose(onDateReset) {
    this.setState({ formData: {} });
    onDateReset();
    this.setState({ joblandlordCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updatejoblandlordCreateModal: false });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleChangeInputLocation = (event, val) => {
    console.log('event, val  ', event.value);
    const { formData } = this.state;
    formData["location_id"] = event.value;
    this.setState({ formData });
  };
  handleChangeInputDesignation = (event, val) => {
    console.log('event, val  ', event.value);
    const { formData } = this.state;
    formData["designation_Id"] = event.value;
    this.setState({ formData });
  };
  handleChangeInputContractor = (event, val) => {
    console.log('event, val  ', event.value);
    const { formData } = this.state;
    formData["assign_to"] = event.value;
    this.setState({ formData });
  };
  handleChangeInputInternal = (event, val) => {
    console.log('event, val  ', event.value);
    const { formData } = this.state;
    formData["internalId"] = event.value;
    this.setState({ formData });
  };
  handleDateChange = (dates) => {
    const dateArray = [];
    dates.map((date) => {
      var dateString = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .split("T")[0];
      dateArray.push(dateString);
    });

    this.setState({ jobType_PM: dateArray });
  };
  handleOpt = (e) => {
    const { opt } = this.state;
    opt[e.target.name] = e.target.value;
    this.setState({ opt });
  };
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };

  handleSubmit = (dates, onDateReset) => {
    console.log("dates______::", dates);
    const dateArray = [];
    dates.map((date) => {
      dateArray.push(date.format(format));
    });

    console.log("dateArray_______:", dateArray);

    // if (dateArray.length === 0) {
    //   alert("Please select date.");
    //   return;
    // }

    const min = 100000;
    const max = 999999;
    const rand = min + Math.floor(Math.random() * (max - min));
    const prefix =
      this.state.formData.jobType_Id === "6019c8929fba3c2d3b9dad25"
        ? "DM - "
        : "PM - ";
    let reqData = {
      job_title: this.state.formData.job_title,
      jobType_Id: this.state.formData.jobType_Id,
      job_id: prefix + rand,
      location_id: this.state.formData.location_id,
      // assign_to: this.state.formData.assign_to,
      // assinged_IS: null,
      job_details: this.state.formData.job_details,
      designation_Id: this.state.formData.designation_Id,
      internalId: this.state.formData.internalId,
      jobType_PM: dateArray,
    };
    // this.state.formData.assignToId === "CONTRACTOR" ? 

    if (this.state.formData.assignToId === "CONTRACTOR") {
      reqData["assign_to"] = this.state.formData.assign_to;
      reqData["assinged_IS"] = null;
    } else {
      reqData["assign_to"] = null;
      reqData["assinged_IS"] = this.state.formData.internalId;
    }

    console.log("reqDatareqDatareqDatareqDatareqData:", reqData);
    this.props.dispatch(joblandlordActions.addJobLandlord(reqData));
    onDateReset();
  };
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      firstName: this.state.updateformData.firstName,
      email: this.state.updateformData.email,
      lastName: this.state.updateformData.lastName,
      gender: this.state.updateformData.gender,
      dob: this.state.updateformData.dob,
      doj: this.state.updateformData.doj,
      mobile: this.state.updateformData.mobile,
      address: this.state.updateformData.address,
      height: this.state.updateformData.height,
      weight: this.state.updateformData.weight,
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(joblandlordActions.updateJobLandlord(reqData));
  };
  onChangeFile(event) {
    this.props.dispatch(
      joblandlordActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(joblandlordActions.deleteJobLandlord(tempdata));
  }
  onDisable(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    //console.log("asdf :: ",tempdata);
    this.props.dispatch(joblandlordActions.disableJobLandlord(tempdata));
  }

  onChangeHandler(event) {
    const { value } = event.target;
    console.log("event.target.value::", value);
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedColoumn: value,
        keyWord: "",
        startDate: "",
        endDate: "",
        formData: {
          jobType: "",
        },
      };
    });
    if (value != "date_filter") {
      let data = {
        columnName: value,
        filter: "pending",
        keyWord: "",
        pageNo: 1,
        size: this.state.size,
      };
      this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
    }
  }
  handleOnDateSelect = (date, type) => {
    if (date) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            [type]: date.format(format),
            offset: 0,
          };
        },
        () => {
          if (this.state.startDate && this.state.endDate) {
            console.log('++++++++++++++++++++++++++++++++++++++++');
            let data = {
              filter: "pending",
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              pageNo: 1,
              size: this.state.size,
            };
            this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
          }
        },
      );
    } else {
      let data = {
        keyWord: "",
        pageNo: 1,
        size: this.state.size,
        filter: "pending",
        // columnName: "job_title",
      };
      this.props.dispatch(joblandlordActions.getFilteredAssignedJob(data));
    }
  }


  handleOnDateSelectSearch = (date, type) => {
    if (date) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            [type]: date.format(format),
            offset: 0,
          };
        },

      );
    }
  }

  inputChangeSearch = (e) => {
    e.preventDefault();
    console.log('inputChangeSearch_called');
    let { searchInput } = this.state
    let { name, value } = e.target;
    searchInput[name] = value
    this.setState(searchInput);
  }
  searchJob = () => {
    if (this.state.searchInput) {
      if (this.state.startDate && this.state.endDate) {
        let data = {
          keyWord: "",
          pageNo: 1,
          size: this.state.size,
          filter: "pending",
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          searchInput: this.state.searchInput,
        };
        this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
      } else {
        let data = {
          keyWord: "",
          pageNo: 1,
          size: this.state.size,
          filter: "pending",
          searchInput: this.state.searchInput,
          // columnName: "job_title",
        };
        this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
      }

    }
    else {
      let data = {
        keyWord: "",
        pageNo: 1,
        size: this.state.size,
        filter: "pending",
        // searchInput: this.state.searchInput,
        // columnName: "job_title",
      };
      this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
    }
    console.log('000000000000000000');
  }

  reset = () => {
    this.setState({
      searchInput: {
        searchTitle: '',
        searchJobType: '',
        searchAddr: '',
        searchDesig: '',
      },
    })
    this.setState({ startDate: "" })
    this.setState({ endDate: "" })
    let data = {
      // columnName: this.state.selectedColoumn,
      filter: "pending",
      keyWord: this.state.keyWord,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(joblandlordActions.getJobLandlordFilteredList(data));
  }


  render() {
    let { joblandlords, internalstaff, classes } = this.props;
    let {
      items,
      designationListitems,
      contractItems,
      locationListitems,
      getContractTypeList,
      total,
      jobTypeItems,
    } = joblandlords;
    let {
      itemsJob
    } = internalstaff;
    console.log("itemsJob_______________", itemsJob);
    // console.log("this.state.formData______RENDER_________", this.state.formData);
    console.log("this.state.siteInfoData______RENDER_________", this.state.siteInfoData);
    return (
      <>
        <PageTitle title="Jobs" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField id="searchTitle" label="Job Title" variant="standard" name="searchTitle" value={this.state.searchInput.searchTitle} onChange={this.inputChangeSearch} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField id="searchAddr" label="Job Address" variant="standard" name="searchAddr" value={this.state.searchInput.searchAddr} onChange={this.inputChangeSearch} />
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <FormControl className={classes.formControl} style={{ marginTop: '16px' }}  >
                        <Select style={{ width: '205px' }}
                          select
                          variant="standard"
                          name="searchJobType"
                          labelId="select-label"
                          id="select"
                          value={this.state.searchInput.searchJobType}
                          onChange={this.inputChangeSearch}
                          displayEmpty
                        >
                          <MenuItem value="">Select Job Type</MenuItem>
                          <MenuItem value={dmID} key={dmID}> DM </MenuItem>
                          <MenuItem key={pmID} value={pmID}> PM </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className={classes.formControl} style={{ marginTop: '16px' }} >
                        <Select style={{ width: '205px' }}
                          select
                          variant="standard"
                          labelId="select-label"
                          id="select"
                          name="searchDesig"
                          value={this.state.searchInput.searchDesig}
                          onChange={this.inputChangeSearch}
                          displayEmpty
                        >
                          <MenuItem value="">Select Designation</MenuItem>
                          {designationListitems &&
                            designationListitems.length > 0 &&
                            designationListitems.map((designation) => {
                              return (
                                <MenuItem
                                  key={designation.id}
                                  value={designation.id}
                                >
                                  {designation.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={5} xs={12} >
                      <FormLabel style={{ fontSize: "13px" }} >Start Date</FormLabel>
                      <DatePicker
                        value={this.state.startDate}
                        onChange={(date) => this.handleOnDateSelectSearch(date, "startDate")}
                        className={classes.rmdpTopClass}
                        sort
                        zIndex={1500}
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={
                          this.state.endDate ? this.state.endDate : new Date()
                        }
                      />
                    </Grid>
                    <Grid item md={5} xs={12} >
                      <FormLabel style={{ fontSize: "13px" }} >End Date </FormLabel>
                      <DatePicker
                        value={this.state.endDate}
                        onChange={(date) => this.handleOnDateSelectSearch(date, "endDate")}
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={new Date()}
                        minDate={this.state.startDate && this.state.startDate}
                        style={{ height: "26px" }}
                      />
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Button style={{ marginRight: "15px" }} variant="contained" onClick={this.searchJob}>Search</Button>
                      <Button variant="contained" onClick={this.reset}>Reset</Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sm></Grid>
                {(this.state.selectedColoumn === "job_title" ||
                  this.state.selectedColoumn === "address") && (
                    <Grid item>
                      <TextField
                        id="standard-search"
                        label={
                          this.state.selectedColoumn === "job_title"
                            ? "Search title"
                            : "Search address"
                        }
                        type="search"
                        name="keyWord"
                        onChange={this.handleSearch}
                        className={classes.textField}
                        value={this.state.keyWord}
                        margin="normal"
                      />
                    </Grid>
                  )}

                {this.state.selectedColoumn === "job_type" && (
                  <Grid item>
                    <FormControl
                      className={classes.formControl}
                      style={{ marginTop: "33px", marginLeft: "20px" }}
                    >
                      <Select
                        select
                        variant="standard"
                        labelId="select-label"
                        id="select"
                        value={this.state.formData.jobType}
                        onChange={this.handleOnSelectChange}
                        displayEmpty
                      >
                        <MenuItem value="">Please Select</MenuItem>

                        <MenuItem value={dmID} key={dmID}>
                          DM
                        </MenuItem>
                        <MenuItem key={pmID} value={pmID}>
                          PM
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.state.selectedColoumn === "date_filter" && (
                  <Grid item className={classes.dateBox}>
                    <Grid item className={classes.dateStyle}>
                      <FormLabel
                        style={{ marginRight: "20px", fontSize: "13px" }}
                      >
                        Start Date
                      </FormLabel>

                      <DatePicker
                        value={this.state.startDate}
                        onChange={(date) =>
                          this.handleOnDateSelect(date, "startDate")
                        }
                        className={classes.rmdpTopClass}
                        sort
                        zIndex={1500}
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={
                          this.state.endDate ? this.state.endDate : new Date()
                        }
                        style={{ height: "26px" }}
                      />
                    </Grid>
                    <Grid item className={classes.dateStyle}>
                      <FormLabel
                        style={{ marginRight: "20px", fontSize: "13px" }}
                      >
                        End Date
                      </FormLabel>

                      <DatePicker
                        value={this.state.endDate}
                        onChange={(date) =>
                          this.handleOnDateSelect(date, "endDate")
                        }
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={new Date()}
                        minDate={this.state.startDate && this.state.startDate}
                        style={{ height: "26px" }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleOpenCreateModal()}
                    style={{ marginTop: "28px", marginLeft: "15px" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {items && items.length > 0 ? (
                <>
                  <Table
                    data={items}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    handleOpenViewCreateModal={this.handleOpenViewCreateModal}
                    onDeleteClick={this.onDeleteClick}
                    deleteJob={this.deleteJob}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClickSearch(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <AddJobLandlordDialog
          userCreateModal={this.state.joblandlordCreateModal}
          classes={classes}
          itemsJob={itemsJob}
          formData={this.state.formData}
          jobTypeItems={jobTypeItems ? jobTypeItems : []}
          locationListitems={locationListitems ? locationListitems : []}
          designationListitems={
            designationListitems ? designationListitems : []
          }
          opt={this.state.opt}
          contractItems={contractItems ? contractItems : []}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleChangeInputLocation={this.handleChangeInputLocation}
          handleChangeInputDesignation={this.handleChangeInputDesignation}
          handleChangeInputContractor={this.handleChangeInputContractor}
          handleChangeInputInternal={this.handleChangeInputInternal}
          handleClose={this.handleAddClose}
          handleDateChange={this.handleDateChange}
        />
        <UpdateJobLandlordDialog
          updatejoblandlordCreateModal={this.state.updatejoblandlordCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          f
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
        />
        <ViewJobLandlordDialog
          jobCreateModal={this.state.ViewjoblandlordCreateModal}
          classes={classes}
          handleClose={this.handleClose}
          viewRowData={this.state.viewRowData}
          siteInfoData={this.state.siteInfoData}

        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { internalstaff, joblandlords } = state;
  return {
    joblandlords, internalstaff
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(JobLandlordManagement)),
);
