import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Switch,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  sortArrow: {
    width: "15.2px",
    position: "absolute",
    marginLeft: "5px",
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    updatehandleOpenCreateModal,
    updatePwdhandleOpenCreateModal,
    handleSort,
    sortOrder,
    sortBy,
    classes,
    onDisable,
  } = props;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell>S. No.</TableCell>
              <TableCell> Package Name</TableCell>
              <TableCell> Package Minimum</TableCell>
              <TableCell> Package Maximum</TableCell>
              <TableCell> Description</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index}>
                <TableCell className="pl-3 fw-normal">
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.packageId ? tempdata.packageId.packName : '-'}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.packageId ? tempdata.packageId.min : 0}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.packageId ? tempdata.packageId.max : 0}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.packageId ? tempdata.packageId.desc : '-'}
                </TableCell>
                <TableCell>{tempdata && tempdata.isApprove === true ? <Button
                  color="success"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: "5px" }}

                >
                  Approved
                </Button> : <Button
                  color="primary"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: "5px" }}

                >
                  Pending
                </Button>}</TableCell>
                <TableCell>{tempdata.contact}</TableCell>

                {/* <TableCell>
                  <Switch
                    checked={tempdata.active}
                    onChange={() => onDisable(tempdata)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell> */}
                {/* <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => updatehandleOpenCreateModal(tempdata)}
                  >
                    update
                  </Button>

                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => updatePwdhandleOpenCreateModal(tempdata)}
                  >
                    update password
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
