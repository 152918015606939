import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import CircleIcon from '@mui/icons-material/Circle';
// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 1024,
  },
});

function TableComponent(props) {
  let { data, offset, classes, handleOpenViewCreateModal, deleteJob } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell style={{ width: "10%" }}>S. No.</TableCell>
              <TableCell style={{ width: "10%" }}>Status</TableCell>
              <TableCell style={{ width: "20%" }}>Job Title</TableCell>
              <TableCell style={{ width: "15%" }}>Job Id</TableCell>
              <TableCell style={{ width: "15%" }}>Designation</TableCell>
              <TableCell style={{ width: "25%" }}>Address</TableCell>
              <TableCell style={{ width: "15%" }}>Action</TableCell>
              <TableCell style={{ width: "15%" }}>Deletion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index}>
                <TableCell className="pl-3 fw-normal">
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {(tempdata && tempdata.start_date == 0) || (tempdata && tempdata.start_date == '') ? <><CircleIcon style={{ color: 'red', width: '12px' }} /></> : <><CircleIcon style={{ color: 'green', width: '12px' }} /></>}

                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.job_title}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.jobType_Id && tempdata.jobType_Id.id
                    ? tempdata.job_id
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.designation_Id && tempdata.designation_Id.name
                    ? tempdata.designation_Id.name
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata && tempdata.location_id
                    ? tempdata.location_id.linecomplete
                    : "-"}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => handleOpenViewCreateModal(tempdata)}
                  >
                    More Details
                  </Button>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Button
                    color="warning"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => deleteJob(tempdata)}
                  >
                    Delete Job
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
