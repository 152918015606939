export const joblandlordConstants = {
    LOGIN_REQUEST: 'JOBLANDLORDS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'JOBLANDLORDS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'JOBLANDLORDS_LOGIN_FAILURE',

    LOGOUT: 'JOBLANDLORDS_LOGOUT',


    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    JOBLANDLORDS_GETALL_REQUEST: 'JOBLANDLORDS_GETALL_REQUEST',
    JOBLANDLORDS_GETALL_SUCCESS: 'JOBLANDLORDS_GETALL_SUCCESS',
    JOBLANDLORDS_GETALL_FAILURE: 'JOBLANDLORDS_GETALL_FAILURE',

    REMOVE_JOB_REQUEST: 'REMOVE_JOB_REQUEST',
    REMOVE_JOB_SUCCESS: 'REMOVE_JOB_SUCCESS',
    REMOVE_JOB_FAILURE: 'REMOVE_JOB_FAILURE',

    GETALL_LANDLORD_JOBTYPE_REQUEST: 'JOBLANDLORDS_GETALL_LANDLORD_JOBTYPE_REQUEST',
    GETALL_LANDLORD_JOBTYPE_SUCCESS: 'JOBLANDLORDS_GETALL_LANDLORD_JOBTYPE_SUCCESS',
    GETALL_LANDLORD_JOBTYPE_FAILURE: 'JOBLANDLORDS_GETALL_LANDLORD_JOBTYPE_FAILURE',

    GETALL_LANDLORD_CONTRACT_REQUEST: 'JOBLANDLORDS_GETALL_LANDLORD_CONTRACT_REQUEST',
    GETALL_LANDLORD_CONTRACT_SUCCESS: 'JOBLANDLORDS_GETALL_LANDLORD_CONTRACT_SUCCESS',
    GETALL_LANDLORD_CONTRACT_FAILURE: 'JOBLANDLORDS_GETALL_LANDLORD_CONTRACT_FAILURE',

    GET_ALL_JOBLANDLORD_LOCATION_REQUEST: 'JOBLANDLORDS_GET_ALL_JOBLANDLORD_LOCATION_REQUEST',
    GET_ALL_JOBLANDLORD_LOCATION_SUCCESS: 'JOBLANDLORDS_GET_ALL_JOBLANDLORD_LOCATION_SUCCESS',
    GET_ALL_JOBLANDLORD_LOCATION_FAILURE: 'JOBLANDLORDS_GET_ALL_JOBLANDLORD_LOCATION_FAILURE',

    GET_ALL_JOBLANDLORD_DESIGNATION_REQUEST: 'JOBLANDLORDS_GET_ALL_JOBLANDLORD_DESIGNATION_REQUEST',
    GET_ALL_JOBLANDLORD_DESIGNATION_SUCCESS: 'JOBLANDLORDS_GET_ALL_JOBLANDLORD_DESIGNATION_SUCCESS',
    GET_ALL_JOBLANDLORD_DESIGNATION_FAILURE: 'JOBLANDLORDS_GET_ALL_JOBLANDLORD_DESIGNATION_FAILURE',

    GETALL_JOBLANDLORD_NOTIFY_REQUEST: 'GETALL_JOBLANDLORD_NOTIFY_REQUEST',
    GETALL_JOBLANDLORD_NOTIFY_SUCCESS: 'GETALL_JOBLANDLORD_NOTIFY_SUCCESS',
    GETALL_JOBLANDLORD_NOTIFY_FAILURE: 'GETALL_JOBLANDLORD_NOTIFY_FAILURE',

    UPDATE_JOBLANDLORD_NOTIFY_REQUEST: 'UPDATE_JOBLANDLORD_NOTIFY_REQUEST',
    UPDATE_JOBLANDLORD_NOTIFY_SUCCESS: 'UPDATE_JOBLANDLORD_NOTIFY_SUCCESS',
    UPDATE_JOBLANDLORD_NOTIFY_FAILURE: 'UPDATE_JOBLANDLORD_NOTIFY_FAILURE',


    ADD_RESTAURANT_JOBLANDLORD_REQUEST: 'ADD_RESTAURANT_JOBLANDLORD_REQUEST',
    ADD_RESTAURANT_JOBLANDLORD_SUCCESS: 'ADD_RESTAURANT_JOBLANDLORD_SUCCESS',
    ADD_RESTAURANT_JOBLANDLORD_FAILURE: 'ADD_RESTAURANT_JOBLANDLORD_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
