import { packageConstants } from '../_constants';
import { packageService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
export const packageActions = {
    login,
    logout,
    // addPackage,
    updatePassword,
    uploadImage,
    disablePackage,
    deletePackage,
    updatePackage,
    getAllPackage,
    getPackageList,
    approvePackage,
    disableUserPackage,
    deleteUserPackage,
    getUserPackageList,
    createPackage,
    getISPackageList,
    getMostRecentISPackByUserId,
    getPackageListForLand,
    getCurrentISPackageByUserId,
    getISPackageListByUser,
    buyISPackage,
    approveISPackage
};
function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        packageService.login(data)
            .then(
                packages => {
                    dispatch(success(packages));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: packageConstants.LOGIN_REQUEST } }
    function success(packages) { return { type: packageConstants.LOGIN_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.LOGIN_FAILURE, error } }
}
function logout() {
    packageService.logout();
    return { type: packageConstants.LOGOUT };
}
function getPackageList(data) {
    return dispatch => {
        dispatch(request());
        packageService.getPackageList(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GETALL_REQUEST } }
    function success(packages) { return { type: packageConstants.GETALL_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GETALL_FAILURE, error } }
}
function getPackageListForLand(data) {
    return dispatch => {
        dispatch(request());
        packageService.getPackageListForLand(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GETALL_PACK_FOR_LAND_REQUEST } }
    function success(packages) { return { type: packageConstants.GETALL_PACK_FOR_LAND_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GETALL_PACK_FOR_LAND_FAILURE, error } }
}
function getMostRecentISPackByUserId(data) {
    return dispatch => {
        dispatch(request());
        packageService.getMostRecentISPackByUserId(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.RECENT_USER_PACK_REQUEST } }
    function success(packages) { return { type: packageConstants.RECENT_USER_PACK_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.RECENT_USER_PACK_FAILURE, error } }
}
function getCurrentISPackageByUserId(data) {
    return dispatch => {
        dispatch(request());
        packageService.getCurrentISPackageByUserId(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GET_CURRENT_USER_PACK_REQUEST } }
    function success(packages) { return { type: packageConstants.GET_CURRENT_USER_PACK_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GET_CURRENT_USER_PACK_FAILURE, error } }
}
function getISPackageListByUser(data) {
    return dispatch => {
        dispatch(request());
        packageService.getISPackageListByUser(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GET_USER_PACK_LIST_REQUEST } }
    function success(packages) { return { type: packageConstants.GET_USER_PACK_LIST_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GET_USER_PACK_LIST_FAILURE, error } }
}
function getUserPackageList(data) {
    return dispatch => {
        dispatch(request());
        packageService.getUserPackageList(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GET_ALL_USER_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.GET_ALL_USER_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GET_ALL_USER_PACKAGE_FAILURE, error } }
}
function getISPackageList(data) {
    return dispatch => {
        dispatch(request());
        packageService.getISPackageList(data)
            .then(
                packages => dispatch(success(packages)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GET_ALL_IS_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.GET_ALL_IS_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GET_ALL_IS_PACKAGE_FAILURE, error } }
}

function updatePassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "roll_id": 0,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        packageService.updatePassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(packageActions.getPackageList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.UPDATE_PACKAGE_PASSWORD_REQUEST } }
    function success(users) { return { type: packageConstants.UPDATE_PACKAGE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: packageConstants.UPDATE_PACKAGE_PASSWORD_FAILURE, error } }
}

function createPackage(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        packageService.createPackage(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Package added successfully."));
                    dispatch(success(users));
                    dispatch(packageActions.getPackageList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.ADD_PACKAGE_REQUEST } }
    function success(users) { return { type: packageConstants.ADD_PACKAGE_SUCCESS, users } }
    function failure(error) { return { type: packageConstants.ADD_PACKAGE_FAILURE, error } }
}

function getAllPackage(data) {
    return dispatch => {
        dispatch(request());
        packageService.getAllPackage(data)
            .then(
                packages => {
                    //console.log("packages  ",packages);
                    let returnResppnse =
                        packages.getAllPackage ? packages.getAllPackage.map(worker => ({})) : [];
                    let newRespnse = { ...packages, getAllPackage: returnResppnse }
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.GET_ALL_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.GET_ALL_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.GET_ALL_PACKAGE_FAILURE, error } }
}
function addPackage(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
    }
    return dispatch => {
        dispatch(request());
        packageService.addPackage(data)
            .then(
                packages => {
                    dispatch(alertActions.success("Package added successfully."));
                    dispatch(success(packages));
                    dispatch(this.getPackageList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_FAILURE, error } }
}
function updatePackage(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10,

    }
    return dispatch => {
        dispatch(request());
        packageService.updatePackage(data)
            .then(
                packages => {
                    dispatch(alertActions.success("Package update successfully."));
                    dispatch(success(packages));
                    dispatch(this.getPackageList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.UPDATE_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.UPDATE_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.UPDATE_PACKAGE_FAILURE, error } }
}
function disablePackage(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        packageService.disablePackage(maintempdata)
            .then(
                packages => {
                    dispatch(success(packages));
                    dispatch(this.getPackageList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_FAILURE, error } }
}
function deletePackage(data, pagination) {
    console.log('IIIIIIIIIIIIIII', data + "UUUUU" + pagination);
    return dispatch => {
        dispatch(request());
        packageService.deletePackage(data)
            .then(
                packages => {
                    dispatch(alertActions.success("Package delete successfully."));
                    dispatch(success(packages));
                    dispatch(this.getPackageList(pagination));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.ADD_RESTAURANT_PACKAGE_FAILURE, error } }
}

//userPackage
function disableUserPackage(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        packageService.disableUserPackage(maintempdata)
            .then(
                packages => {
                    dispatch(success(packages));
                    dispatch(this.getUserPackageList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.UPDATE_USER_PACKAGE_STATUS_REQUEST } }
    function success(packages) { return { type: packageConstants.UPDATE_USER_PACKAGE_STATUS_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.UPDATE_USER_PACKAGE_STATUS_FAILURE, error } }
}
function approvePackage(data, paginationData) {
    return dispatch => {
        dispatch(request());
        packageService.approvePackage(data)
            .then(
                packages => {
                    dispatch(success(packages));
                    dispatch(this.getUserPackageList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.APPROVE_USER_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.APPROVE_USER_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.APPROVE_USER_PACKAGE_FAILURE, error } }
}
function approveISPackage(data, paginationData) {
    return dispatch => {
        dispatch(request());
        packageService.approveISPackage(data)
            .then(
                packages => {
                    dispatch(success(packages));
                    dispatch(this.getISPackageList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.APPROVE_IS_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.APPROVE_IS_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.APPROVE_IS_PACKAGE_FAILURE, error } }
}
function buyISPackage(data, pagination) {
    return dispatch => {
        dispatch(request());
        packageService.buyISPackage(data)
            .then(
                packages => {
                    dispatch(success(packages));
                    dispatch(alertActions.success("Request Send..."));
                    dispatch(this.getMostRecentISPackByUserId(pagination));
                    dispatch(this.getISPackageListByUser(pagination));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.BUY_IS_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.BUY_IS_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.BUY_IS_PACKAGE_FAILURE, error } }
}
function deleteUserPackage(data, pagination) {
    console.log('IIIIIIIIIIIIIII', data + "UUUUU" + pagination);
    return dispatch => {
        dispatch(request());
        packageService.deleteUserPackage(data)
            .then(
                packages => {
                    dispatch(alertActions.success("User package delete successfully."));
                    dispatch(success(packages));
                    dispatch(this.getUserPackageList(pagination));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: packageConstants.DELETE_USER_PACKAGE_REQUEST } }
    function success(packages) { return { type: packageConstants.DELETE_USER_PACKAGE_SUCCESS, packages } }
    function failure(error) { return { type: packageConstants.DELETE_USER_PACKAGE_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        packageService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: packageConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: packageConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
