export const CONST = {
  // BACKEND_URL: 'http://localhost:9002/api/v1',

  // BACKEND_URL: 'http://localhost:9002/api/dadmin',
  // BACKEND_URL2: 'http://localhost:9002/api/v1',

  BACKEND_URL: "https://alltracksystem.com/api/dadmin",
  // BACKEND_URL: "https://stagingatsadmin.alltracksystem.com/api/dadmin",
  // BACKEND_URL2: "https://alltracksystem.com/api/v1",

  // BACKEND_URL: "http://139.162.218.18:9002/api/dadmin",
  // BACKEND_URL2: "http://139.162.218.18:9002/api/v1",

  // BACKEND_URL: ' https://jsonplaceholder.typicode.com/todos',
  // BACKEND_URL:
  //   "http://ec2-3-140-243-216.us-east-2.compute.amazonaws.com/ats/api/dadmin",
  // BACKEND_URL2:
  //   "http://ec2-3-140-243-216.us-east-2.compute.amazonaws.com/ats/api/v1",
  FILE_URL: "https://atsadmin.alltracksystem.com",

  // FILE_URL: "https://stagingatsadmin.alltracksystem.com",
};
