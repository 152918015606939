import React, { } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  Grid,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

let location = [];
let id = "";

function LocationTableComponent(props) {
  let {
    locationID,
    landlord,
    handleOpenLocationCreateModal,
    locationHandler,
    keyWord,
    handleSearch,
  } = props;

  if (landlord) {
    location = landlord.location;
    id = landlord.id;
  }

  const generateAddress = (tempdata) => {
    return `${tempdata.line1} ${tempdata.line2} ${tempdata.line3} 
    ${tempdata.city} 
    ${tempdata.state} 
    ${tempdata.pin}`;
  };

  const handleOnLocationClick = (location) => {
    // console.log("location", location);
    locationHandler(location, true);
  };

  const classes = "";
  return (
    <Paper
      className={classes.root}
      style={{ marginRight: "40px", marginTop: "25px", marginBottom: "15px", overflowY: "auto", height: "920px" }}
    >
      <Grid container>
        <Grid item sm></Grid>
        <Grid item>
          <TextField
            id="standard-search"
            label="Search location"
            type="search"
            name="keyWord"
            onChange={handleSearch}
            // className={classes.textField}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenLocationCreateModal(id)}
            // className={classes.button}
            style={{ marginBottom: "10px" }}
          >
            Add Location
          </Button>
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell style={{ cursor: "pointer" }}>Sr.no</TableCell>
              <TableCell style={{ cursor: "pointer" }}>Address</TableCell>

              <TableCell style={{ cursor: "pointer" }}>City</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              location.map((tempdata, index) => (
                <TableRow
                  key={index}
                  style={{
                    cursor: "pointer",
                    background:
                      locationID === tempdata.id ? "#f0f8ff" : "#ffffff",
                  }}
                  onClick={() => {
                    handleOnLocationClick(tempdata);
                  }}
                >
                  <TableCell className="pl-3 fw-normal">
                    {index + 1}
                  </TableCell>

                  <TableCell className="pl-3 fw-normal">
                    {generateAddress(tempdata)}
                  </TableCell>

                  <TableCell className="pl-3 fw-normal">
                    {tempdata.city}
                  </TableCell>
                </TableRow>
              )
              )}

            {/* {location && keyWord &&
              location.filter((elementesmp) => (elementesmp.line1.includes(keyWord) || elementesmp.line2.includes(keyWord) || elementesmp.line3.includes(keyWord) || elementesmp.city.includes(keyWord) || elementesmp.state.includes(keyWord))).map((tempdata, index) => (
                <TableRow
                  key={index}
                  style={{
                    cursor: "pointer",
                    background:
                      locationID === tempdata.id ? "#f0f8ff" : "#ffffff",
                  }}
                  onClick={() => {
                    handleOnLocationClick(tempdata);
                  }}
                >
                  <TableCell className="pl-3 fw-normal">
                    {index + 1}
                  </TableCell>

                  <TableCell className="pl-3 fw-normal">
                    {generateAddress(tempdata)}
                  </TableCell>

                  <TableCell className="pl-3 fw-normal">
                    {tempdata.city}
                  </TableCell>
                </TableRow>
              ))} */}
          </TableBody>

          {location.length === 0 && (
            <TableBody>
              <TableCell>Data Not Found</TableCell>
            </TableBody>
          )}
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(LocationTableComponent);
