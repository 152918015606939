import React, { Component } from "react";
import {
  Grid,
  //LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
} from "@material-ui/core";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
// import GoogleMap from "../../components/GoogleMap";
import { Typography } from "../../components/Wrappers/Wrappers";

class Dashboard extends Component {
  constructor(props) {
    document.title = "ATS | Admin";
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      markers: [],
      places: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(userActions.landlordStaticsData());
  }

  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }

  render() {
    let classes = {};
    // const { places } = this.state;
    let { users } = this.props;
    let { staticsData, landlordStatsData } = users;
    return (
      <>
        <PageTitle title="Dashboard" />
        <Grid container spacing={4}>
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Landlords"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {staticsData ? staticsData.landloards : 0}
                </Typography>
              </div>
            </Widget>
          </Grid> */}
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Contractors"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {landlordStatsData ? landlordStatsData.contractor : 0}
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Internal Staff"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {landlordStatsData ? landlordStatsData.internalStaff : 0}
                </Typography>
              </div>
            </Widget>
          </Grid>
        </Grid>
      </>
    );
  }
}
Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    users,
  };
}
export default connect(mapStateToProps)(Dashboard);
