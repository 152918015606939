import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";



export default function UpdateWorkerDialog(props) {

  let { classes, updateformData, updateuserCreateModal, updatehandleClose, updatehandleChangeInput, updatehandleSubmit } = props;
  console.log('updateformData________', updateformData);
  return (
    <Dialog fullScreen open={updateuserCreateModal} onClose={updatehandleClose} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={updatehandleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update Package
          </Typography>
          <Button color="inherit" onClick={updatehandleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Package
          </Typography>

          <ValidatorForm
            onSubmit={updatehandleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>

                <TextValidator
                  label="packName*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="packName"
                  id="packName"
                  value={updateformData.packName}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>

                <TextValidator
                  label="Slug*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="slug"
                  id="slug"
                  value={updateformData.slug}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="desc*"
                  id="desc"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="desc"
                  value={updateformData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Minimum*"
                  id="min"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="min"
                  disabled={true}
                  value={updateformData.min}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Maximum*"
                  id="max"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="max"
                  disabled={true}
                  value={updateformData.max}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
              <TextValidator
                label="password*"
                id="password"
                fullWidth
                onChange={updatehandleChangeInput}
                name="password"
                value={updateformData.password}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid> */}
              {/* <Grid item xs={12} sm={12}>
              <TextValidator
                label="gender*"
                id="gender"
                fullWidth
                onChange={updatehandleChangeInput}
                name="gender"
                value={updateformData.gender}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid> */}
              {/* <Grid item xs={12} sm={12}>
            <TextValidator
                label="dob*"
                id="dob"
                fullWidth
                onChange={updatehandleChangeInput}
                name="dob"
                value={updateformData.dob}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid> */}
              {/* <Grid item xs={12} sm={12}>
            <TextValidator
                label="doj*"
                id="doj"
                fullWidth
                onChange={updatehandleChangeInput}
                name="doj"
                value={updateformData.doj}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid> */}
              {/* <Grid item xs={12} sm={6}>
            <TextValidator
                label="contact*"
                id="contact"
                fullWidth
                onChange={updatehandleChangeInput}
                name="contact"
                value={updateformData.contact}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="address*"
                id="address"
                fullWidth
                onChange={updatehandleChangeInput}
                name="address"
                value={updateformData.address}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid> */}
              {/* <Grid item xs={12} sm={6}>
            <TextValidator
                label="height*"
                id="height"
                fullWidth
                onChange={updatehandleChangeInput}
                name="height"
                value={updateformData.height}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="weight*"
                id="weight"
                fullWidth
                onChange={updatehandleChangeInput}
                name="weight"
                value={updateformData.weight}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid> */}
              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
