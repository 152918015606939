import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddWorkerDialog from "./components/AddWorkerDialog/AddWorkerDialog";
import UpdateWorkerDialog from "./components/UpdateWorkerDialog/UpdateWorkerDialog";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog/UpdatePasswordDialog";
import { confirmAlert } from 'react-confirm-alert';
import Widget from "../../components/Widget/Widget";
import CardMedia from '@mui/material/CardMedia';
import Table from "./components/Table/Table";
import { workerActions, contractorActions, userActions, packageActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';




const theme = createMuiTheme();

class WorkerManagement extends Component {
  constructor(props) {
    document.title = "ATS | Contractor";
    super(props);

    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      sortBy: "contractor",
      sortOrder: "1",
      workerCreateModal: false,
      updateworkerCreateModal: false,
      updatePasswordCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        address: "",
        contact: "",
        roll_id: "",
        parentId: "",
      },
      updateformData: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        contact: "",
        parentId: "",
      },
      updatePwdformData: {
        id: "",
        password: "",
      },
      newPassword: {},

    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(userActions.landlordStaticsData());
    this.props.dispatch(packageActions.getPackageListForLand(data));
    this.props.dispatch(packageActions.getMostRecentISPackByUserId());
    // this.props.dispatch(packageActions.getCurrentISPackageByUserId());
    this.props.dispatch(packageActions.getISPackageListByUser());

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.workers.addWorkerSuccess) {
      return {
        ...nextProps,
        formData: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          address: "",
          contact: "",
          roll_id: "",
          parentId: "",
        },
        updatePwdformData: {
          id: "",
          password: "",
        },
        newPassword: {},
        workerCreateModal: false,
        updateworkerCreateModal: false,
        updatePasswordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }


  buyPackage = (data) => {
    console.log('datadatadatadatadata', data);
    let tempdata = {
      "packageId": data.id,
    }
    let paginationdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to buy Package?',
      message: `Are you sure to buy ${data.packName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(packageActions.buyISPackage(tempdata, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  render() {
    let { workers, contractors, classes, users, packages } = this.props;
    let { items, total, landPackitems, currentPack, recentPackItems, userPackages } = packages;
    let { landlordStatsData } = users;
    let { isApprove, max } = recentPackItems ? recentPackItems : {};
    // let { internalStaff } = landlordStatsData;


    console.log('RENDER_userPackages_____________', landlordStatsData,);

    return (
      <>


        <PageTitle title="Packages" />


        <Grid container>
          <Grid item xs={12} md={12}>
            <Widget>
              <Grid container spacing={3} style={{ marginBottom: "15px" }} >
                {landPackitems && landPackitems.length > 0 ? landPackitems.map((element) => (<>
                  <Grid item xs={3}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image="https://blog.vantagecircle.com/content/images/2020/08/Employee-Benefits-Compensation-Ideas-1.png"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <h4>{element.desc}</h4>
                        </Typography>
                      </CardContent>



                      {max === element.max && isApprove === true ? <>
                        <span><h3>{landlordStatsData ? landlordStatsData.internalStaff : 0}/{element.max}</h3></span>
                        <span><h2>Already Upgraded</h2></span>
                      </> : <>{max < element.max ? <CardActions>
                        <Button
                          color="success"
                          size="small"
                          className="px-2"
                          variant="contained"
                          style={{ marginRight: "5px" }}
                          onClick={() => { this.buyPackage(element) }}
                        >
                          Upgrade
                        </Button>
                      </CardActions> : null}</>



                      }

                    </Card>
                  </Grid>
                </>)) : null}
              </Grid>
            </Widget>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Widget>
              <Grid container>
                <Grid item sm />
                <Grid item>
                  <Typography><b>User Requests</b></Typography>

                </Grid>
              </Grid>
              {userPackages && userPackages.list && userPackages.list.length > 0 ? (
                <>
                  <Table
                    data={userPackages.list}
                    sortOrder={this.state.sortOrder}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    handleSort={this.handleSort}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    updatePwdhandleOpenCreateModal={
                      this.updatePwdhandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
            </Widget>
          </Grid>
        </Grid>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { workers, contractors, users, packages } = state;
  return {
    workers,
    contractors,
    users, packages
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(WorkerManagement)),
);
