import {
    jobConstants
  } from '../_constants';
  
  export function jobs(state = {}, action) {
  
    switch (action.type) {
  
  
      case jobConstants.GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobConstants.GETALL_SUCCESS:
        return {
          ...state,
          addJobSuccess: false,
          items: action.jobs.getJobList.list,
          total: action.jobs.getJobList.total,
          imageUrl: action.jobs.getJobList.imageUrl,
        };
      case jobConstants.GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobConstants.GET_ALL_JOB_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobConstants.GET_ALL_JOB_SUCCESS:
        return {
          ...state,
          getAllJob: action.jobs.getAllJob
        };
      case jobConstants.GET_ALL_JOB_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobConstants.GETALL_JOB_NOTIFY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobConstants.GETALL_JOB_NOTIFY_SUCCESS:
        return {
          ...state,
          listOfNotification: action.jobs.listOfNotification.list,
          listOfNotificationtotal: action.jobs.listOfNotification.total
        };
      case jobConstants.GETALL_JOB_NOTIFY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobConstants.UPDATE_JOB_NOTIFY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobConstants.UPDATE_JOB_NOTIFY_SUCCESS:
        return {
          ...state,
        };
      case jobConstants.UPDATE_JOB_NOTIFY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobConstants.GET_RESTAURANT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobConstants.GET_RESTAURANT_SUCCESS:
        return {
          ...state,
          addMenuSuccess: false,
          addItemSuccess: false,
          updateitem: false,
          updateCategory: false,
          restaurantDetails: action.jobs.restaurantDetails
        };
      case jobConstants.GET_RESTAURANT_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobConstants.ADD_RESTAURANT_JOB_REQUEST:
        return {
          ...state
        };
      case jobConstants.ADD_RESTAURANT_JOB_SUCCESS:
        return {
          ...state,
          addJobSuccess: true
        };
      case jobConstants.ADD_RESTAURANT_JOB_FAILURE:
        return {
          ...state,
          error: action.error
        };
      case jobConstants.ADD_MENU_REQUEST:
        return {
          ...state
        };
      case jobConstants.ADD_MENU_SUCCESS:
        return {
          ...state,
          addMenuSuccess: true
        };
      case jobConstants.ADD_MENU_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobConstants.ADD_ITEM_REQUEST:
        return {
          ...state
        };
      case jobConstants.ADD_ITEM_SUCCESS:
        return {
          ...state,
          addItemSuccess: true
        };
      case jobConstants.ADD_ITEM_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobConstants.UPDATE_CATEGORY_REQUEST:
        return {
          ...state
        };
      case jobConstants.UPDATE_CATEGORY_SUCCESS:
        return {
          ...state,
          updateCategory: true
        };
      case jobConstants.UPDATE_CATEGORY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobConstants.UPDATE_ITEM_REQUEST:
        return {
          ...state
        };
      case jobConstants.UPDATE_ITEM_SUCCESS:
        return {
          ...state,
          updateitem: true
        };
      case jobConstants.UPDATE_ITEM_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobConstants.FILE_UPLOAD_STATUS_SUCCESS:
        return {
          ...state, filesDetails: action.uploadImage.filesDetails,
        };
  
      case jobConstants.FILE_UPLOAD_STATUS_FAILURE:
        return {
          ...state
        };
  
  
      case jobConstants.STATS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobConstants.STATS_SUCCESS:
        return {
          ...state,
          statics: action.jobs.statics
        };
      case jobConstants.STATS_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      default:
        return state
    }
  }