import {
  packageConstants
} from '../_constants';

export function packages(state = {}, action) {

  switch (action.type) {


    case packageConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GETALL_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        items: action.packages.getPackageList.list,
        total: action.packages.getPackageList.total
      };
    case packageConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case packageConstants.GETALL_PACK_FOR_LAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GETALL_PACK_FOR_LAND_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        landPackitems: action.packages.getPackageListForLand.list,
        landPackTotal: action.packages.getPackageListForLand.total
      };
    case packageConstants.GETALL_PACK_FOR_LAND_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.RECENT_USER_PACK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.RECENT_USER_PACK_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        recentPackItems: action.packages.getMostRecentISPackByUserId
      };
    case packageConstants.RECENT_USER_PACK_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.GET_CURRENT_USER_PACK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_CURRENT_USER_PACK_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        currentPack: action.packages.getCurrentISPackageByUserId
      };
    case packageConstants.GET_CURRENT_USER_PACK_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.GET_USER_PACK_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_USER_PACK_LIST_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        userPackages: action.packages.getISPackageListByUser
      };
    case packageConstants.GET_USER_PACK_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    //userPackage
    case packageConstants.GET_ALL_USER_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_ALL_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        userPackItems: action.packages.getUserPackageList.list,
        userPackTotal: action.packages.getUserPackageList.total
      };
    case packageConstants.GET_ALL_USER_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case packageConstants.GET_ALL_IS_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_ALL_IS_PACKAGE_SUCCESS:
      return {
        ...state,
        addPackageSuccess: false,
        isPackItems: action.packages.getISPackageList.list,
        isPackTotal: action.packages.getISPackageList.total
      };
    case packageConstants.GET_ALL_IS_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.GET_ALL_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_ALL_PACKAGE_SUCCESS:
      return {
        ...state,
        getAllPackage: action.packages.getAllPackage
      };
    case packageConstants.GET_ALL_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.UPDATE_PACKAGE_PASSWORD_REQUEST:
      return {
        ...state
      };
    case packageConstants.UPDATE_PACKAGE_PASSWORD_SUCCESS:
      return {
        ...state,
        addPackageSuccess: true
      };
    case packageConstants.UPDATE_PACKAGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.GETALL_PACKAGE_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GETALL_PACKAGE_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.packages.listOfNotification.list,
        listOfNotificationtotal: action.packages.listOfNotification.total
      };
    case packageConstants.GETALL_PACKAGE_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // case packageConstants.UPDATE_PACKAGE_NOTIFY_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case packageConstants.UPDATE_PACKAGE_NOTIFY_SUCCESS:
    //   return {
    //     ...state,
    //   };
    // case packageConstants.GETALL_PACKAGE_NOTIFY_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    case packageConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.packages.restaurantDetails
      };
    case packageConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case packageConstants.ADD_RESTAURANT_PACKAGE_REQUEST:
      return {
        ...state
      };
    case packageConstants.ADD_RESTAURANT_PACKAGE_SUCCESS:
      return {
        ...state,
        addPackageSuccess: true
      };
    case packageConstants.ADD_RESTAURANT_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case packageConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case packageConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case packageConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case packageConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case packageConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case packageConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case packageConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case packageConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case packageConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case packageConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case packageConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case packageConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case packageConstants.ADD_PACKAGE_REQUEST:
      return {
        ...state
      };
    case packageConstants.ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case packageConstants.ADD_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.UPDATE_PACKAGE_REQUEST:
      return {
        ...state
      };
    case packageConstants.UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case packageConstants.UPDATE_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case packageConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case packageConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    case packageConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.packages.statics
      };
    case packageConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    //userPackage
    case packageConstants.UPDATE_USER_PACKAGE_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.UPDATE_USER_PACKAGE_STATUS_SUCCESS:
      return {
        ...state,
        statics: action.packages.statics
      };
    case packageConstants.UPDATE_USER_PACKAGE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.APPROVE_USER_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.APPROVE_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        statics: action.packages.statics
      };
    case packageConstants.APPROVE_USER_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.APPROVE_IS_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.APPROVE_IS_PACKAGE_SUCCESS:
      return {
        ...state,
        // statics: action.packages.statics
      };
    case packageConstants.APPROVE_IS_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.BUY_IS_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.BUY_IS_PACKAGE_SUCCESS:
      return {
        ...state,
        // statics: action.packages.statics
      };
    case packageConstants.BUY_IS_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.DELETE_USER_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.DELETE_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        statics: action.packages.statics
      };
    case packageConstants.DELETE_USER_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}