import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import DatePicker, { } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ViewArchiveDialog from "./components/ViewArchiveDialog/ViewArchiveDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { archiveActions } from "../../_actions";
import { joblandlordActions, jobActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();

const dmID = "6019c8929fba3c2d3b9dad25";
const pmID = "6019c001d78e4b27bd2ee187";
const format = "YYYY-MM-DD";

class ArchiveManagement extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpenViewCreateModal = this.handleOpenViewCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      ViewarchiveCreateModal: false,
      archiveCreateModal: false,
      updatearchiveCreateModal: false,
      submitted: false,
      keyWord: "",
      jobType: "",
      startDate: "",
      endDate: "",
      formData: {
        job_title: "",
        jobType_Id: "",
        job_id: "",
        location_id: "",
        assign_to: "",
        assinged_IS: null,
        job_details: "",
        designation_Id: "",
      },
      searchInput: {
        searchTitle: '',
        searchJobType: '',
        searchAddr: '',
        searchDesig: '',
      },
      selectedColoumn: "",
    };
  }

  componentDidMount() {
    let data = {
      // columnName: this.state.selectedColoumn,
      filter: "completed",
      keyWord: this.state.keyWord,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(archiveActions.getArchiveList(data));
    this.props.dispatch(joblandlordActions.getDesignationList());
    // this.handleTime();
  }

  handleClick = (offset, page) => {
    this.setState({ offset, page });
    let data = {
      columnName: this.state.selectedColoumn,
      filter: "completed",
      keyWord: this.state.keyWord,
      pageNo: page,
      size: this.state.size,
    };
    this.props.dispatch(archiveActions.getArchiveList(data));
  };

  handleClickSearch = (offset, page) => {
    this.setState({ offset, page });
    if (this.state.searchInput) {
      if (this.state.startDate && this.state.endDate) {
        let data = {
          // columnName: this.state.selectedColoumn,
          filter: "completed",
          searchInput: this.state.searchInput,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          pageNo: page,
          size: this.state.size,
        };
        this.props.dispatch(archiveActions.getArchiveList(data));
      }
      else {
        let data = {
          filter: "completed",
          searchInput: this.state.searchInput,
          keyWord: this.state.keyWord,
          pageNo: page,
          size: this.state.size,
        };
        this.props.dispatch(archiveActions.getArchiveList(data));
      }

    }

    else {
      let data = {
        columnName: this.state.selectedColoumn,
        filter: "completed",
        keyWord: this.state.keyWord,
        pageNo: page,
        size: this.state.size,
      };
      this.props.dispatch(archiveActions.getArchiveList(data));
    }
  };


  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      columnName: this.state.selectedColoumn,
      filter: "completed",
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(archiveActions.getArchiveList(data));
  }

  // handleTime() {
  //   // event.preventDefault();
  //   // let { value } = event.target;
  //   let data = {
  //     "jobId": "62f98a7436ea3b3f3d83c72f",
  //     "timeData": [
  //       {
  //         "onSiteTime": 1660643405,
  //         "offSiteTime": 1660643505
  //       },
  //       {
  //         "onSiteTime": 1660643705,
  //         "offSiteTime": 1660643805
  //       },
  //       {
  //         "onSiteTime": 1660643905,
  //         "offSiteTime": 1660644005
  //       }
  //     ]
  //   };
  //   this.props.dispatch(archiveActions.getTimeData(data));
  // }

  handleOpenViewCreateModal(data) {
    let tempTime = {};
    let req = {
      "jobId": data.id,
    }
    this.setState({ ViewarchiveCreateModal: true, viewRowData: data });
    // this.props.dispatch(archiveActions.getTimeData(req));

  }

  handleClose() {
    this.setState({ ViewarchiveCreateModal: false });
  }
  onChangeHandler(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedColoumn: value,
        keyWord: "",
        jobType: "",
      };
    });
    let data = {
      columnName: value,
      filter: "completed",
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(archiveActions.getArchiveList(data));
  }

  handleOnSelectChange(event) {
    const { value } = event.target;
    let data = {
      columnName: this.state.selectedColoumn,
      filter: "completed",
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
    };
    this.setState((prevState) => {
      return {
        ...prevState,
        jobType: value,
        keyWord: value,
        offset: 0,
      };
    });
    this.props.dispatch(archiveActions.getArchiveList(data));
  }

  handleOnDateSelectSearch = (date, type) => {
    if (date) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            [type]: date.format(format),
            offset: 0,
          };
        },

      );
    }
  }

  inputChangeSearch = (e) => {
    e.preventDefault();
    console.log('inputChangeSearch_called');
    let { searchInput } = this.state
    let { name, value } = e.target;
    searchInput[name] = value
    this.setState(searchInput);
  }
  searchJob = () => {
    if (this.state.searchInput) {
      if (this.state.startDate && this.state.endDate) {
        let data = {
          keyWord: "",
          pageNo: 1,
          size: this.state.size,
          filter: "completed",
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          searchInput: this.state.searchInput,
        };
        this.props.dispatch(archiveActions.getArchiveList(data));
      } else {
        let data = {
          keyWord: "",
          pageNo: 1,
          size: this.state.size,
          filter: "completed",
          searchInput: this.state.searchInput,
          // columnName: "job_title",
        };
        this.props.dispatch(archiveActions.getArchiveList(data));
      }

    }
    else {
      let data = {
        keyWord: "",
        pageNo: 1,
        size: this.state.size,
        filter: "completed",
        // searchInput: this.state.searchInput,
        // columnName: "job_title",
      };
      this.props.dispatch(archiveActions.getArchiveList(data));
    }
    console.log('000000000000000000');
  }

  reset = () => {
    this.setState({
      searchInput: {
        searchTitle: '',
        searchJobType: '',
        searchAddr: '',
        searchDesig: '',
      },
    })
    this.setState({ startDate: "" })
    this.setState({ endDate: "" })
    let data = {
      // columnName: this.state.selectedColoumn,
      filter: "completed",
      keyWord: this.state.keyWord,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(archiveActions.getArchiveList(data));
  }

  render() {
    let { archives, classes, joblandlords } = this.props;
    let { designationListitems } = joblandlords;
    let {
      items,
      // designationListitems,
      // contractItems,
      // locationListitems,
      total, imageUrl, timeItems,
    } = archives;
    console.log("Render________imageUrl____________", imageUrl);
    console.log("total____________", total)
    return (
      <>
        <PageTitle title="Archived Jobs" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField id="searchTitle" label="Job Title" variant="standard" name="searchTitle" value={this.state.searchInput.searchTitle} onChange={this.inputChangeSearch} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField id="searchAddr" label="Job Address" variant="standard" name="searchAddr" value={this.state.searchInput.searchAddr} onChange={this.inputChangeSearch} />
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <FormControl className={classes.formControl} style={{ marginTop: '16px' }}  >
                        <Select style={{ width: '205px' }}
                          select
                          variant="standard"
                          name="searchJobType"
                          labelId="select-label"
                          id="select"
                          value={this.state.searchInput.searchJobType}
                          onChange={this.inputChangeSearch}
                          displayEmpty
                        >
                          <MenuItem value="">Select Job Type</MenuItem>
                          <MenuItem value={dmID} key={dmID}> DM </MenuItem>
                          <MenuItem key={pmID} value={pmID}> PM </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className={classes.formControl} style={{ marginTop: '16px' }} >
                        <Select style={{ width: '205px' }}
                          select
                          variant="standard"
                          labelId="select-label"
                          id="select"
                          name="searchDesig"
                          value={this.state.searchInput.searchDesig}
                          onChange={this.inputChangeSearch}
                          displayEmpty
                        >
                          <MenuItem value="">Select Designation</MenuItem>
                          {designationListitems &&
                            designationListitems.length > 0 &&
                            designationListitems.map((designation) => {
                              return (
                                <MenuItem
                                  key={designation.id}
                                  value={designation.id}
                                >
                                  {designation.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={5} xs={12} >
                      <FormLabel style={{ fontSize: "13px" }} >Start Date</FormLabel>
                      <DatePicker
                        value={this.state.startDate}
                        onChange={(date) => this.handleOnDateSelectSearch(date, "startDate")}
                        className={classes.rmdpTopClass}
                        sort
                        zIndex={1500}
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={
                          this.state.endDate ? this.state.endDate : new Date()
                        }
                      />
                    </Grid>
                    <Grid item md={5} xs={12} >
                      <FormLabel style={{ fontSize: "13px" }} >End Date </FormLabel>
                      <DatePicker
                        value={this.state.endDate}
                        onChange={(date) => this.handleOnDateSelectSearch(date, "endDate")}
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={new Date()}
                        minDate={this.state.startDate && this.state.startDate}
                        style={{ height: "26px" }}
                      />
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Button style={{ marginRight: "15px" }} variant="contained" onClick={this.searchJob}>Search</Button>
                      <Button variant="contained" onClick={this.reset}>Reset</Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm />
                {(this.state.selectedColoumn === "job_title" ||
                  this.state.selectedColoumn === "address") && (
                    <Grid item>
                      <TextField
                        id="standard-search"
                        label={
                          this.state.selectedColoumn === "job_title"
                            ? "Search title"
                            : "Search address"
                        }
                        type="search"
                        name="keyWord"
                        onChange={this.handleSearch}
                        className={classes.textField}
                        value={this.state.keyWord}
                        margin="normal"
                      />
                    </Grid>
                  )}

                {this.state.selectedColoumn === "job_type" && (
                  <Grid item>
                    <FormControl
                      className={classes.formControl}
                      style={{ marginTop: "33px", marginLeft: "20px" }}
                    >
                      <Select
                        select
                        variant="standard"
                        labelId="select-label"
                        id="select"
                        value={this.state.jobType}
                        onChange={this.handleOnSelectChange}
                        displayEmpty
                      >
                        <MenuItem value="">Please Select</MenuItem>

                        <MenuItem value={dmID} key={dmID}>
                          DM
                        </MenuItem>
                        <MenuItem key={pmID} value={pmID}>
                          PM
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              {items && items.length > 0 ? (
                <>
                  <Table
                    data={items}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    handleOpenViewCreateModal={this.handleOpenViewCreateModal}
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClickSearch(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <ViewArchiveDialog
          jobCreateModal={this.state.ViewarchiveCreateModal}
          classes={classes}
          // data={items}
          // formData={this.state.formData}
          viewRowData={this.state.viewRowData}
          imageUrl={imageUrl}
          onOffTime={timeItems}
          // handleSubmit={this.handleSubmit}
          // handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { archives, joblandlords } = state;
  return {
    archives, joblandlords
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ArchiveManagement)),
);
